import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  //**Open an close the mobile menu */
  showMobileItems = false;

  //**Variables to change the menu depending on the route */
  urlsMain = [
    '/dashboard',
    '/network/locations',
    '/network/schedules',
    '/advertising/monetization',
    '/revenue',
    '/billing',
    '/account',
    '/advertising/internal-promotions',
    '/content/schedule',
    '/content/library'
  ];
  urlsAuth = ['/sign-up', '/forgot'];

  //**Principal routes */
  routes: any[] = [
    {
      url: '/dashboard',
      name: 'DASHBOARD',
    },
    {
      name: 'NETWORK',
      subRouteInfo: {
        openDesktop: false,
        openMobile: false,
        subRoutes: [
          {
            url: '/network/locations',
            name: 'Clinics & Screens'
          },
          {
            url: '/network/schedules',
            name: 'Schedules'
          }
        ]
      }
    },
    {
      url: '/revenue',
      name: 'REVENUE',
    },
    {
      name: 'CONTENT',
      subRouteInfo: {
        openDesktop: false,
        openMobile: false,
        subRoutes: [
          {
            url: '/content/schedule',
            name: 'Schedule Content'
          },
          {
            url: '/content/library',
            name: 'Content Library'
          }
        ]
      }
    },
    {
      url: '/account',
      name: 'ACCOUNT',
    },
    {
      url: '/login',
      name: 'LOGOUT',
    },
  ];

  /**Open an close principal routes when the navbar is mobile */
  showPrincipalMobileRoutes = true;

  /**
   *
   * @param router to manage the routing of the app
   * @param uiService to manage the modals
   */
  constructor(public router: Router, public uiService: UiService, private authService: AuthService) {}

  /**
   * Method to navigate when the page is mobile
   * @param route to know which page go
   */
  navigateMobile(route: any) {
    if (route.subRouteInfo) {
      //open subroutes in mobiles
      route.subRouteInfo.openMobile = route.subRouteInfo.openMobile ? false : true;
      this.showPrincipalMobileRoutes = false;
    } else {
      this.router.navigateByUrl(route.url);
      this.showMobileItems = false;
    }
  }

  /**
   * Method to close subroutes in mobile
   */
  closeSubRoutes() {
    this.showPrincipalMobileRoutes = true;
    this.routes.forEach((route) => {
      if (route.subRouteInfo) {
        route.subRouteInfo.openMobile = false;
      }
    });
  }

  /**
   * Method to navigate in deskptop screent
   * @param route object to know which page go
   * @param eventSubroute to know if comes from a subroute
   */
  navigate(route: any, eventSubroute?: any) {
    if (route.url === '/login') {
      this.authService.logout();
    }
    route.url && this.router.navigateByUrl(route.url);
    if (eventSubroute) {
      eventSubroute.stopPropagation();
    }
  }
  /**
   * Method to open and close subroutes in desktop screen
   * @param route object to know which page go
   * @param action could be open or close
   */
  toggleSubRoute(route: any, action: 'open' | 'close') {
    if (route.subRouteInfo) {
      route.subRouteInfo.openDesktop = action === 'open' ? true : false;
    }
  }

  validateCurrentTab(route: any) {
    if (route.subRouteInfo) {
      for (const subRoute of route.subRouteInfo.subRoutes) {
        if (this.router.url.includes(subRoute.url)) {
          return true;
        }
      }
    }
    return this.router.url.includes(route.url)
  }
}
